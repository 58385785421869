/* swiper
   -------------------------------------------------------------------------- */
   const Swiper01 = new Swiper('.p-home__works__list__slide.swiper > .swiper-container', {
    speed: 500,
    slidesPerView: 1.25,
    centeredSlides: true,
    loop: true,
    autoplay: {
        disableOnInteraction: true,
        delay: 3000,
    },
    navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev'
	},
    breakpoints:{
        1601: {
          slidesPerView: 3.5,
        },
        1201: {
            slidesPerView: 2.5,
        },
        821: {
          slidesPerView: 1.5,
        },
        601: {
          slidesPerView: 1.5,
        },
      },
})

const imgDescShow = () => {
  
  const slideImg = document.querySelectorAll('.js-slide-target');
  const imgDesc = document.querySelectorAll('.js-img-desc');

  for (let _i = 0; _i < slideImg.length; _i++){
    slideImg[_i].addEventListener('click', () => {
      imgDesc[_i].classList.toggle('is-show');
      setTimeout(() => {
        imgDesc[_i].classList.remove('is-show')
      }, 5000);
    });
  };

};
document.addEventListener('DOMContentLoaded', imgDescShow);
    